import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import { leaderboardData, highScores, recentWinners } from './mockData';
import TokenMetrics from './tokens/TokenMetrics';
import fetchTokens, { Token } from './types/token';
import { TOKEN_ADDRESS, API_KEY } from './types/token';
import Leaderboard from './Leaderboard';
import GameStory from './GameStory';
import ComicStory from './ComicStory';
// Add in your Helius API key
const url = `https://mainnet.helius-rpc.com/?api-key=${API_KEY}`;

interface Window {
    playSound: (sound: string) => void;
    shakeElement: (element: HTMLElement) => void;
    // Add any other global functions you've defined
}

const Home = () => {
    const [isMuted, setIsMuted] = useState(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isShaking, setIsShaking] = useState(false);
    const [tokenInfo, setTokenInfo] = useState<Token | null>(null);
    const [leaderboard, setLeaderboard] = useState(leaderboardData);
    const [topScores, setTopScores] = useState(leaderboardData);
    const [winners, setWinners] = useState(recentWinners);
    const [tokenDistribution, setTokenDistribution] = useState<{[key: string]: number}>({});
    const [showInsaneDescription, setShowInsaneDescription] = useState(false);

    useEffect(() => {

        const bgMusic = new Audio('https://www.myinstants.com/media/sounds/lets-go.mp3');
        bgMusic.loop = true;
    
        const playAudio = () => {
            if (audioRef.current) {
                audioRef.current.play().catch(error => console.log("Audio play failed:", error));
            }
        };

        document.addEventListener('click', playAudio, { once: true });


        // const toggleMute = () => {
        //   if (isMuted) {
        //     bgMusic.play().catch(error => console.log("Audio play failed:", error));
        //   } else {
        //     bgMusic.pause();
        //   }
        //   setIsMuted(!isMuted);
        // };
    
        // const muteButton = document.getElementById('muteButton');
        // if (muteButton) {
        //   muteButton.addEventListener('click', toggleMute);
        // }
        // Periodic shaking effect
        const shakeInterval = setInterval(() => {
            setIsShaking(true);
            setTimeout(() => setIsShaking(false), 500);
        }, 5000);
        return () => {
            document.removeEventListener('click', playAudio);
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }
            clearInterval(shakeInterval);
        };

      }, [isMuted]);
    
    const handleBuyClick = () => {
        // if (typeof window.playSound === 'function') {
        //   window.playSound('wow');
        // }
      };
      
    useEffect(() => {
        // Load and execute home.js script
        const script = document.createElement('script');
        script.src = '/home.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        const fetchData = async () => {
            const tokens = await fetchTokens();
            console.log(tokens);
            if (tokens) {
                setTokenInfo(tokens);
            }
        };

        fetchData();

      const findHolders = async () => {
        let page = 1;
        let allOwners = new Set();
        let distribution: {[key: string]: number} = {};

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                jsonrpc: "2.0",
                method: "getTokenAccounts",
                id: "helius-test",
                params: {
                    page: page,
                    limit: 1000,
                    displayOptions: {},
                    // Replace this mint address with the token address you are interested in
                    mint: TOKEN_ADDRESS,
                },
            }),
        });
        const data = await response.json();

        if (data.result.token_accounts.length > 0) {
            console.log(`No more results. Total pages: ${page - 1}`);
            console.log(`Processing results from page ${page}`);
            data?.result?.token_accounts?.forEach((account: any) => {
                if (distribution[account.owner]) {
                    distribution[account.owner] += Number(account.amount);
                } else {
                    distribution[account.owner] = Number(account.amount);
                }
            });
            // page++;
            setTokenDistribution(distribution);
        }
        data.result.token_accounts.forEach((account: any) =>
            allOwners.add(account.owner),
        );
    };

        findHolders();


        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const toggleMute = () => {
        setIsMuted(!isMuted);
        if (audioRef.current) {
            if (isMuted) {
                audioRef.current.play().catch(error => console.log("Audio play failed:", error));
            } else {
                audioRef.current.pause();
            }
        }
    };
    const toggleDescription = () => {
        setShowInsaneDescription(!showInsaneDescription);
    };

  return (
    <div className="container">
      <header>
        <h1 className="glitch-effect">$FLING</h1>
        <p className="tagline">Ready to Moon? Don't Miss Out! Win Big!</p>
      </header>
      <div className="game-section">
        <Link to="/play" className="play-button" id="playButton">PLAY NOW</Link>
      </div>
      <GameStory />
      <ComicStory />
      <div className="game-description-container">
        <h2 className="game-description-title">How to Play</h2>
        <div className="mode-toggle-container">
          <button onClick={toggleDescription} className="mode-toggle-button">
            {showInsaneDescription ? "Show Chill Mode" : "Show Meme Madness"}
          </button>
        </div>
        
        <div className="game-description">
          <div className="gameplay-instructions">
            <h3>Gameplay Basics</h3>
            <ul className="instruction-list">
              <li><span className="emoji">➡️</span> Use arrow keys or touch controls to move left and right⬅️</li>
              <li><span className="emoji">⬆️</span> Press up arrow or tap to jump (double jump available!)</li>
              <li><span className="emoji">🕒</span> Race against the clock - you have 60 seconds!</li>
              <li><span className="emoji">💰</span> Collect coins and power-ups for points and more time</li>
              <li><span className="emoji">💥</span> Avoid obstacles - they'll cost you points!</li>
              <li><span className="emoji">🏆</span> Aim for the highest score to top the leaderboard</li>
              <li><span className="emoji">🎁</span> Top the leaderboard to win a share of 10,000,000 $FLING!</li>
            </ul>
          </div>

          {showInsaneDescription ? (
            <div className="insane-description">
              <h3 className="mode-title">🚀 MEME MADNESS MODE 🚀</h3>
              <p>Buckle up, memers! This ain't your grandma's game mode!</p>
              <ul className="feature-list">
                <li><span className="emoji">🎭</span> Memes and objects fly at you faster than ever!</li>
                <li><span className="emoji">💥</span> Screen shakes for extra challenge</li>
                <li><span className="emoji">🔥</span> More frequent power-ups and obstacles</li>
                <li><span className="emoji">🏆</span> Higher risk, higher reward - rack up insane scores!</li>
              </ul>
              <p className="warning">Warning: May cause uncontrollable laughter and meme addiction!</p>
            </div>
          ) : (
            <div className="normal-description">
              <h3 className="mode-title">😎 CHILL MODE 😎</h3>
              <p>Welcome to the laid-back side of meme gaming!</p>
              <ul className="feature-list">
                <li><span className="emoji">🎮</span> Relaxed pace, perfect for beginners</li>
                <li><span className="emoji">🏅</span> Earn points at a steady rate</li>
                <li><span className="emoji">🎁</span> Regular power-ups to boost your score</li>
                <li><span className="emoji">🔍</span> Learn the ropes and discover game mechanics</li>
              </ul>
              <p>Perfect for meme enthusiasts of all levels!</p>
            </div>
          )}

          <div className="power-ups-section">
            <h3>Power-Ups</h3>
            <ul className="power-ups-list">
              <li><span className="emoji">⚡</span> Speed Boost: Move faster for a limited time</li>
              <li><span className="emoji">🦘</span> Super Jump: Leap higher than ever before</li>
              <li><span className="emoji">⏱️</span> Time Extension: Add precious seconds to the clock</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`fling-info ${isShaking ? 'shake' : ''}`}>
        <h2 className="glitch-effect">What is $FLING?</h2>
        <p>$FLING is the hottest meme token in the crypto universe! 🚀🌕</p>
        <ul>
          <li>🔥 Deflationary tokenomics</li>
          <li>💎 Diamond hand community</li>
          <li>🎮 Play-to-earn mechanics</li>
          <li>🌈 Regular airdrops for hodlers</li>
        </ul>
        <div className="token-stats">
          <div className="stat">
                <span className="stat-label">Total Supply:</span>
                <span className="stat-value">{tokenInfo?.token_info?.supply?.toLocaleString()} $FLING</span>
            </div>
            <div className="stat">
                <span className="stat-label">Burned:</span>
                <span className="stat-value">{tokenInfo?.burnt?.toLocaleString()} $FLING</span>
            </div>
            <div className="stat">
                <span className="stat-label">Current Price:</span>
                {/* <span className="stat-value">${tokenInfo?.token_info?.price?.toFixed(8)}</span> */}
            </div>
        </div>
        {/* <TokenMetrics totalSupply={tokenInfo.totalSupply} burned={tokenInfo.burned} /> */}
        {tokenInfo && <TokenMetrics fungibleTokens={[tokenInfo]} distribution={tokenDistribution} />}
        <button className="buy-button" onClick={() => window.open('https://pump.fun/AMcqiLFbAQYfURh6HqbvZfAsTTYGSDaaJJi2NK12pump', '_blank')}>Buy $FLING Now!</button>
      </div>
      <Leaderboard />

        <div className="game-section">
            <a href="https://moon.viman.sh/play" target="_blank" className="play-button" id="playButton">PLAY NOW</a>
        </div>

        {/* <div className="high-scores">
            <h2>Current High Scores</h2>
            <ul className="score-list" id="highScoresList">
                {topScores.map((score, index) => (
                    <li key={index} className="score-item">
                        <span>{score.user}</span>
                        <span>{score.highScore}</span>
                        <span>{score.gamesPlayed}</span>
                    </li>
                ))}
            </ul>
        </div> */}
        <div className="winners-section">
            <h2>Recent Airdrop Winners</h2>
            <ul className="winner-list" id="winnersList">
                {winners.map((winner, index) => (
                    <li key={index} className="winner-item">
                        <span>{winner.username}</span>
                        <span>{winner.amount} $FLING</span>
                        <span className="winner-badge">Winner!</span>
                    </li>
                ))}
            </ul>
        </div>
        <div className="meme-gallery">
            <div className="meme-item">
                <img src="https://i.imgflip.com/1bij.jpg" alt="Meme 1" />
                <div className="meme-caption">Me watching the charts</div>
            </div>
            <div className="meme-item">
                <img src="https://i.imgflip.com/3oevdk.jpg" alt="Meme 2" />
                <div className="meme-caption">HODL $FLING</div>
            </div>
            <div className="meme-item">
                <img src="https://i.imgflip.com/28j0te.jpg" alt="Meme 3" />
                <div className="meme-caption">Join the Meme Revolution!</div>
            </div>
        </div>
        <div className="video-container">
        <video className="money-printer-video" src="https://brrr.money/print.mp4" autoPlay loop muted></video>
            <div className="video-overlay">
                <div className="video-text glitch-effect">MONEY PRINTER GO BRRR!</div>
            </div>
        </div>
        <button 
            id="muteButton" 
            onClick={toggleMute}
            style={{position: 'fixed', fontSize: '20px', top: '20px', right: '20px', zIndex: 1000}}
        >
            {isMuted ? '🔇' : '🔊'}
        </button>

    </div>
  );
};

export default Home;