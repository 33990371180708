import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Token } from '../types/token';

ChartJS.register(ArcElement, Tooltip, Legend);

interface FungibleToken {
  name: string;
  totalSupply: number;
  burned: number;
  currentPrice: number;
}

interface TokenMetricsProps {
  fungibleTokens: Token[];
  distribution: {[key: string]: number};
}

const TokenMetrics: React.FC<TokenMetricsProps> = ({ fungibleTokens, distribution }) => {
  const totalSupply = fungibleTokens[0].token_info.supply || 0;
  const burnedTotal = 0;
  const circulatingTotal = totalSupply - burnedTotal;


  // Sort holders by amount and take top 10
  const topHolders = Object.entries(distribution)
    .sort(([,a], [,b]) => b - a)
    .slice(0, 10);

  const data = {
    // labels: topHolders.map(([address]) => address), i want to send all the data from the topHolders array to the labels
    labels: topHolders.map(([address, amount]) => `${address}: ${amount}`),
    datasets: [
      {
        // send all the data from the topHolders array to the data
        data: topHolders.map(([address, amount]) => amount),
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
          '#FF9F40', '#FF6384', '#C9CBCF', '#4BC0C0', '#FF9F40'
        ],
        hoverBackgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
          '#FF9F40', '#FF6384', '#C9CBCF', '#4BC0C0', '#FF9F40'
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            const label = context.address || '';
            const value = context?.dataset?.data[context.dataIndex] || 0;
            const percentage = ((value / totalSupply) * 100).toFixed(2);
            return `${label}: ${value.toLocaleString()} (${percentage}%)`;
          }
        }
      }
    },
  };
  return (
    <div className="token-metrics">
      <h3>Token Distribution (Top 10 Holders)</h3>
      <div style={{ height: '300px', width: '100%', maxWidth: '500px', margin: '0 auto' }}>
        <Pie data={data} options={options} />
      </div>
      <div className="token-list">
        {fungibleTokens.map((token, index) => (
          <div key={index} className="token-item">
            <h4>{token.content.metadata.name}</h4>
            <p>Total Supply: {token.token_info.supply?.toLocaleString()}</p>
            {/* <p>Burned: {token.burnt?.toLocaleString()}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TokenMetrics;