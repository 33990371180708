export const tokenData = {
    name: "$FLING",
    totalSupply: 1000000000,
    burned: 500000000,
    currentPrice: 0.00069420
  };
  
  export const leaderboardData = [
    { rank: 1, user: "CryptoKing", gamesPlayed: 150, highScore: 10000 },
    { rank: 2, user: "MoonLambo", gamesPlayed: 120, highScore: 9500 },
    { rank: 3, user: "DiamondHands", gamesPlayed: 100, highScore: 9000 },
    { rank: 4, user: "Hodler4Life", gamesPlayed: 90, highScore: 8500 },
    { rank: 5, user: "ToTheMoon", gamesPlayed: 80, highScore: 8000 }
  ];
  
  export const highScores = [
    { username: "wasabi", score: 1240 },
    { username: "MoonLambo", score: 950000 },
    { username: "DiamondHands", score: 900000 }
  ];
  
  export const recentWinners = [
    { username: "LuckyFLINGer", amount: 10000000 },
    { username: "AirdropHunter", amount: 750000 },
    { username: "MemeLoRD", amount: 500000 }
  ];