import React, { useEffect, useState } from 'react';
import { supabase } from './supabaseClient';
import { RealtimeChannel } from '@supabase/supabase-js';

interface Score {
  id: number;
  created_at: string;
  score: number;
  player_name: string;
  coins_collected: number;
  objects_hit: number;
}

const Leaderboard: React.FC = () => {
  const [scores, setScores] = useState<Score[]>([]);

  useEffect(() => {
    fetchScores();

    const channel: RealtimeChannel = supabase
    .channel('public:scores')
    .on('postgres_changes', 
      { event: 'INSERT', schema: 'public', table: 'scores' }, 
      payload => {
        setScores(currentScores => 
          [...currentScores, payload.new as Score]
            .sort((a, b) => b.score - a.score)
            // .slice(0, 100)
        );
      }
    )
    .subscribe();


    return () => {
        supabase.removeChannel(channel);
      };
  }, []);

  const fetchScores = async () => {
    const today = new Date().toISOString().split('T')[0];
    const now = '2024-01-03 01:00:00.000+00';
    const { data, error } = await supabase
      .from('scores')
      .select('*')
      .filter('score', 'gte', 1)// 'created_at', 'gte', now)
      .order('score', { ascending: false })
      // .limit(100);

    if (error) console.log('Error fetching scores:', error);
    else setScores(data || []);
  }

  return (
    <div className="leaderboard">
      <h2 className="leaderboard-title">🏆 Meme Mayhem Leaderboard 🏆</h2>
      <table id="leaderboardTable">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>Score</th>
            <th>Coins</th>
            <th>Objects Hit</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {scores.map((score, index) => (
            <tr key={score.id} className={`leaderboard-row ${index < 3 ? 'top-three' : ''}`}>
              <td className="rank">{index + 1}</td>
              <td className="player-name">{score.player_name}</td>
              <td className="score">{score.score}</td>
              <td className="coins">
                <span role="img" aria-label="coin">🪙</span> {score.coins_collected}
              </td>
              <td className="objects-hit">
                <span role="img" aria-label="collision">💥</span> {score.objects_hit}
              </td>
              <td className='created-at'>{new Date(score.created_at).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;