import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './ComicStory.css';

const ComicStory: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPanel, setCurrentPanel] = useState(0);
  const [isShaking, setIsShaking] = useState(false);
  const speechSynthesis = useRef<SpeechSynthesis | null>(null);
  const speechUtterance = useRef<SpeechSynthesisUtterance | null>(null);

  useEffect(() => {
    speechSynthesis.current = window.speechSynthesis;
    speechUtterance.current = new SpeechSynthesisUtterance();
  }, []);

  const comicPanels = [
    {
      image: '/images/giphy.gif',
      text: "Meet Fling, the kid with wild rainbow hair and star-shaped glasses. He's got a dream: to be the richest, most legendary gamer ever!"
    },
    {
      image: '/images/blip-nobg.png',
      text: "This is Blip, Fling's secret sidekick. A tiny, fuzzy monster that can shapeshift into any emoji or meme!"
    },
    {
      image: '/images/meme-dodge-world.png',
      text: "Together, they dive into the Meme Dodge Game, a world where memes fly and coins are everywhere!"
    },
    {
      image: '/images/fling-dodging.png',
      text: "Fling moves fast, dodging memes left and right. Rainbow cats, dancing frogs, and pepe faces try to trip him up!"
    },
    {
      image: 'https://labs.phaser.io/assets/spine/powerup/powerup.png',
      text: "Blip transforms into power-ups, helping Fling soar through the air and grab every shiny reward in sight!"
    },
    {
      image: '/images/flingblip21.png',
      text: "They face challenges like the giant emoji boss battle! But Fling never gives up, he's got dreams of being a legend!"
    },
    {
      image: 'character1.png',
      text: "As Fling and Blip conquer levels, other players join in. Soon, a whole community forms around their fearless adventures!"
    },
    {
      image: '/images/fling-p2e.jpg',
      text: "With every level up, Fling discovers magical power-ups, hidden treasures, and rare NFT badges!"
    },
    {
      image: '/images/fling-movements.png',
      text: "Fling shares his success, dropping surprise airdrops for his fans and celebrating everyone's victories!"
    },
    {
      image: '/images/flingblip21.png',
      text: "Now, Fling and Blip are on a mission to reach the top, and they want YOU to join them! Are you ready for fun, fortune, and meme greatness?"
    }
  ];

  const nextPanel = () => {
    setIsShaking(true);
    setTimeout(() => setIsShaking(false), 500);
    setCurrentPanel((prev) => (prev + 1) % comicPanels.length);
  };

  const prevPanel = () => {
    setIsShaking(true);
    setTimeout(() => setIsShaking(false), 500);
    setCurrentPanel((prev) => (prev - 1 + comicPanels.length) % comicPanels.length);
  };

  useEffect(() => {
    if (speechSynthesis.current && speechUtterance.current && isOpen) {
      speechSynthesis.current.cancel(); // Stop any ongoing speech
      speechUtterance.current.text = comicPanels[currentPanel].text;
      speechSynthesis.current.speak(speechUtterance.current);
    }
  }, [currentPanel, isOpen]);

  const shakeAnimation = {
    x: [0, -10, 10, -10, 10, 0],
    transition: { duration: 0.5 }
  };

  return (
    <>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsOpen(true)}
        className="comic-button"
      >
        Read the Epic Tale of Fling & Blip!
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="comic-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="comic-modal"
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.5, opacity: 0 }}
              transition={{ type: "spring", stiffness: 300, damping: 25 }}
            >
              <h2>The Legend of Fling & Blip: A Meme Mayhem Adventure</h2>
              <div className="comic-controls">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={prevPanel}
                >
                  Previous
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={nextPanel}
                >
                  Next
                </motion.button>
              </div>
              <motion.div 
                className="comic-container"
                animate={isShaking ? shakeAnimation : {}}
              >
                <motion.div
                  key={currentPanel}
                  initial={{ x: 300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -300, opacity: 0 }}
                  transition={{ type: "spring", stiffness: 300, damping: 25 }}
                  className="comic-panel"
                >
                  <img src={comicPanels[currentPanel].image} alt={`Comic panel ${currentPanel + 1}`} />
                  <div className="text-overlay">
                    <p>{comicPanels[currentPanel].text}</p>
                  </div>
                </motion.div>
              </motion.div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsOpen(false)}
                className="close-button"
              >
                Close
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default ComicStory;