import Phaser from 'phaser';

export default class InstructionsScene extends Phaser.Scene {
  constructor() {
    super('InstructionsScene');
  }

  create() {
    const { width, height } = this.scale;

    // Semi-transparent background
    this.add.rectangle(0, 0, width, height, 0x000000, 0.7).setOrigin(0);

    // Title
    this.add.text(width / 2, 50, 'How to Play', {
      fontSize: '32px',
      color: '#00ff00'
    }).setOrigin(0.5);

    // Instructions
    const instructions = [
      '⬅️➡️ Use arrow keys or touch to move',
      '⬆️ Press up or tap to jump (double jump available!)',
      '🕒 Race against the 60-second clock',
      '💰 Collect coins and power-ups for points',
      '💥 Avoid obstacles - they cost points!',
      '🏆 Aim for the highest score'
    ];

    instructions.forEach((instruction, index) => {
      this.add.text(100, 120 + index * 50, instruction, {
        fontSize: '18px',
        color: '#ffffff'
      });
    });

    // Power-ups
    const powerUps = [
      '⚡ Speed Boost: Move faster',
      '🦘 Super Jump: Leap higher',
      '⏱️ Time Extension: Add seconds'
    ];

    this.add.text(width / 2, height - 200, 'Power-Ups', {
      fontSize: '24px',
      color: '#ffd700'
    }).setOrigin(0.5);

    powerUps.forEach((powerUp, index) => {
      this.add.text(width / 2, height - 150 + index * 40, powerUp, {
        fontSize: '18px',
        color: '#ffd700'
      }).setOrigin(0.5);
    });

    // Close button
    const closeButton = this.add.text(width - 20, 20, 'X', {
      fontSize: '24px',
      color: '#ff0000'
    }).setOrigin(1, 0).setInteractive();

    closeButton.on('pointerdown', () => {
      this.scene.stop();
    });

    // Add some interactivity and animations
    this.tweens.add({
      targets: closeButton,
      scale: 1.2,
      duration: 300,
      yoyo: true,
      repeat: -1
    });

    instructions.forEach((_, index) => {
      const icon = this.add.text(70, 120 + index * 50, '👉', { fontSize: '24px' });
      this.tweens.add({
        targets: icon,
        x: 90,
        duration: 500,
        yoyo: true,
        repeat: -1,
        ease: 'Sine.easeInOut'
      });
    });
  }
}
