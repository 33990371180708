import { createClient } from '@supabase/supabase-js'
// read variables from .env
// import dotenv from 'dotenv';
// dotenv.config();
// import 'dotenv/config';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL as string
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY as string
console.log(supabaseUrl, supabaseAnonKey, )
if (!supabaseUrl || !supabaseAnonKey) {
    throw new Error('Missing Supabase environment variables')
}
export const supabase = createClient(supabaseUrl, supabaseAnonKey)

export const supabaseChannel = supabase.channel('public:scores')

supabaseChannel.on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'scores' }, payload => {
  console.log('New score:', payload);
});

supabaseChannel.subscribe();