import React, { useEffect, useRef, useState } from "react";
import * as Phaser from "phaser";
import { Boot } from "./Boot";
import { Preloader } from "./Preloader";
import { MainMenu } from "./MainMenu";
import { motion } from "framer-motion";
import html2canvas from 'html2canvas';
import { supabase } from "./supabaseClient";
import NameInputScene from "./NameInputScene";
import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import InstructionsScene from "./InstructionsScene";

class GameScene extends Phaser.Scene {
  currentPlayer!: Phaser.Types.Physics.Arcade.ImageWithDynamicBody;
  player!: Phaser.Physics.Arcade.Sprite;
  platforms!: Phaser.Physics.Arcade.StaticGroup;
  cursors!: Phaser.Types.Input.Keyboard.CursorKeys;
  scoreText!: Phaser.GameObjects.Text;
  timerText!: Phaser.GameObjects.Text;
  timer!: Phaser.Time.TimerEvent;
  objects!: Phaser.Physics.Arcade.Group;
  emitter!: Phaser.GameObjects.Particles.ParticleEmitter;
  backgrounds: Phaser.GameObjects.TileSprite[];
  currentBackgroundIndex: number;
  gameOver: boolean;
  score: number;
  spawnTimer!: Phaser.Time.TimerEvent;
  spawnDelay: number = 1000;
  shakeSound!: Phaser.Sound.BaseSound;
  jumpSound!: Phaser.Sound.BaseSound;
  backgroundMusic!: Phaser.Sound.BaseSound;
  backgroundDirection: number;
  jumps: number = 0;
  coinsCollected: number = 0;
  objectsHit: number = 0;
  touchStartX: number | null = null;
  isTouching: boolean = false;
  worldWidth: number = 800; // Initial world width
  platformWidth: number = 800; // Initial platform width
  platformGap: number = 200; // Initial platform gap
  private aiUpdateInterval: number = 100; // Update more frequently
  private aiJumpCooldown: number = 500; // Minimum time between jumps
  private aiJumpProbability: number = 0.3;
  private aiMoveProbability: number = 0.7;
  private lastJumpTime: number = 0;
  private aiMoveDirection: number = 0;
  private lastAiUpdate: number = 0;
  private platformSegments!: Phaser.Physics.Arcade.StaticGroup;
  private segmentWidth: number = 800; // Width of each platform segment

  elapsedTime = 0;
  fixedTimeStep = 1000 / 60;
  cursorKeys!: Phaser.Types.Input.Keyboard.CursorKeys;
  inputPayload = {
    left: false,
    right: false,
    up: false,
    down: false,
    tick: 0,
  };
  currentTick: number = 0;
  private setScore: React.Dispatch<React.SetStateAction<number>>;
  private setGameOver: React.Dispatch<React.SetStateAction<boolean>>;
  private setGameStarted: React.Dispatch<React.SetStateAction<boolean>>;
  private setTime: React.Dispatch<React.SetStateAction<number>>;
  private setGameOverTimestamp: React.Dispatch<React.SetStateAction<string | null>>;
  private setCoinsCollected: React.Dispatch<React.SetStateAction<number>>;
  private setObjectsHit: React.Dispatch<React.SetStateAction<number>>;
  public aiControlled: boolean = false;
  private worldExtensionCount: number = 0;
  private worldExtensionText!: Phaser.GameObjects.Text;
  private timeLeft: number = 60; // Start with 60 seconds
  private timeText!: Phaser.GameObjects.Text;
  private timeEvent!: Phaser.Time.TimerEvent;
  private milestoneParticles!: Phaser.GameObjects.Particles.ParticleEmitter;
  private powerups!: Phaser.Physics.Arcade.Group;
  private minPlayerX: number = 100;
  private platformDirection: 'up' | 'down' | 'straight' = 'straight';
  private lastPlatformY: number = 60;
  private playerName: string = localStorage.getItem('playerName') || 'anon';
  private modeToggle!: Phaser.GameObjects.Text;
  private isInsaneMode: boolean = false;
  private aiToggle!: Phaser.GameObjects.Text;
  // public toggleAiControl() {
  //   this.aiControlled = !this.aiControlled;
  // }
  constructor(setScore: React.Dispatch<React.SetStateAction<number>>, setGameOver: React.Dispatch<React.SetStateAction<boolean>>, setGameStarted: React.Dispatch<React.SetStateAction<boolean>>, setTime: React.Dispatch<React.SetStateAction<number>>, 
    setGameOverTimestamp: React.Dispatch<React.SetStateAction<string | null>>, setCoinsCollected: React.Dispatch<React.SetStateAction<number>>, setObjectsHit: React.Dispatch<React.SetStateAction<number>>) {
    super("GameScene");
    this.gameOver = false;
    this.setScore = setScore;
    this.setGameOver = setGameOver;
    this.setGameStarted = setGameStarted;
    this.score = 0;
    this.backgrounds = [];
    this.currentBackgroundIndex = 0;
    this.backgroundDirection = 1;
    this.setTime = setTime;
    this.setGameOverTimestamp = setGameOverTimestamp;
    this.setCoinsCollected = setCoinsCollected;
    this.setObjectsHit = setObjectsHit;
  }
  takeScreenshot(): Promise<string> {
    return new Promise((resolve) => {
      this.game.renderer.snapshot((snapshot: HTMLImageElement | Phaser.Display.Color) => {
        if (snapshot instanceof HTMLImageElement) {
          resolve(snapshot.src);
        } else {
          // Handle Color object if needed, or resolve with a default value
          resolve('');
        }
      });
    });
  }    
  preload() {
    this.load.audio(
      "shake",
      "https://labs.phaser.io/assets/phaserbyexample/starshake/assets/sounds/foeshot.mp3"
    );
    this.load.audio(
      "jump",
      "https://labs.phaser.io/assets/phaserbyexample/runner/assets/sounds/jump.mp3"
    );
    this.load.image(
      "background1",
      "https://labs.phaser.io/assets/skies/space3.png"
    );
    this.load.image(
      "background2",
      "https://labs.phaser.io/assets/skies/deepblue.png"
    );
    this.load.image(
      "background3",
      "https://labs.phaser.io/assets/games/coin-clicker/background.png" //assets/skies/sky1.png"
    );
    this.load.image(
      "platform",
      "https://labs.phaser.io/assets/sprites/platform.png"
    );
    this.load.image(
      "asteroid",
      "https://labs.phaser.io/assets/games/asteroids/asteroid1.png"
    );
    this.load.image(
      "aliens",
      "https://labs.phaser.io/assets/sprites/alienbusters.png"
    );

    this.load.image(
      "ghost",
      "https://labs.phaser.io/assets/sprites/ghost1.png"
    );

    this.load.image(
      "banana",
      "https://labs.phaser.io/assets/physics/fruit-sprites/banana.png"
    );
    this.load.image(
      "cherry",
      "https://labs.phaser.io/assets/physics/fruit-sprites/cherries.png"
    );
    this.load.image(
      "coin",
      "https://labs.phaser.io/assets/sprites/coin.png"
    );
    this.load.spritesheet(
      "doge",
      "https://labs.phaser.io/assets/sprites/dude.png",
      { frameWidth: 32, frameHeight: 48 }
    );
    this.load.image(
      "particle",
      "https://labs.phaser.io/assets/particles/yellow.png"
    );
    this.load.image(
      "explosion",
      "https://labs.phaser.io/assets/games/lazer/explosion.png"
    );
    this.load.audio(
      "backgroundMusic",
      "https://labs.phaser.io/assets/phaserbyexample/starshake/assets/sounds/music1.mp3"
    );
    this.load.image(
      "stars",
      "https://labs.phaser.io/assets/sprites/star.png"
    );
    this.load.image('powerup_speed', 'https://labs.phaser.io/assets/games/lazer/power-up.png');
    this.load.image('powerup_jump', 'https://labs.phaser.io/assets/games/asteroids/muzzle-flash.png');
    this.load.image('powerup_time', 'https://labs.phaser.io/assets/spine/powerup/powerup.png');
  }
  handleTouchStart(pointer: Phaser.Input.Pointer) {
    this.touchStartX = pointer.x;
    this.isTouching = true;
    this.player.setVelocityY(-330); // Jump on touch
  }
  handleTouchMove(pointer: Phaser.Input.Pointer) {
    if (!this.isTouching || this.touchStartX === null) return;

    const diffX = pointer.x - this.touchStartX;
    if (diffX < -50) {
      // Move left
      this.player.setVelocityX(-160);
      this.player.anims.play('left', true);
    } else if (diffX > 50) {
      // Move right
      this.player.setVelocityX(160);
      this.player.anims.play('right', true);
    } else {
      // Stand still
      this.player.setVelocityX(0);
      this.player.anims.play('turn');
    }
  }

  handleTouchEnd() {
    this.isTouching = false;
    this.touchStartX = null;
    this.player.setVelocityX(0);
    this.player.anims.play('turn');
  }

  createPlatforms() {
    // const platform = this.platforms.create(this.worldWidth / 2, this.scale.height - 32, "platform");
    // platform.setScale(this.platformWidth / platform.width, 1).refreshBody();
    const segments = Math.ceil(this.worldWidth / this.segmentWidth);
    for (let i = 0; i < segments; i++) {
      const x = i * this.segmentWidth + this.segmentWidth / 2;
      const y = this.scale.height - 32;
      const platform = this.platforms.create(x, y, "platform");
      platform.setScale(this.segmentWidth / platform.width, 1).refreshBody();
    }

  }

  async create() {
    // Set world bounds
    this.worldWidth = this.scale.width * 2; // Start with double the screen width
    this.physics.world.setBounds(0, 0, this.worldWidth, this.scale.height);
    this.cameras.main.setBounds(0, 0, this.worldWidth, this.scale.height);
    // Create particle emitter for milestones
    this.milestoneParticles = this.add.particles(0, 0, "particle", {
      speed: 100,
      scale: { start: 1, end: 0 },
      blendMode: "ADD",
    });
    this.playerName = localStorage.getItem('playerName') || 'anon';

    // Use the player name in your game
    this.add.text(400, 300, `Welcome, ${this.playerName}!`, {
      fontSize: '24px',
      color: '#ffffff'
    }).setOrigin(0.5);

    // this.debugGraphics = this.add.graphics().setAlpha(0.5);
    this.input.on('pointerdown', this.handleTouchStart, this);
    this.input.on('pointermove', this.handleTouchMove, this);
    this.input.on('pointerup', this.handleTouchEnd, this);
    this.backgrounds = [
      this.add.tileSprite(0, 0, this.scale.width, this.scale.height, "background1").setOrigin(0, 0).setScrollFactor(0),
      this.add.tileSprite(0, 0, this.scale.width, this.scale.height, "background2").setOrigin(0, 0).setScrollFactor(0),
      this.add.tileSprite(0, 0, this.scale.width, this.scale.height, "background3").setOrigin(0, 0).setScrollFactor(0)
    ];
    // Add world extension counter text
    this.worldExtensionText = this.add.text(16, 16, 'Extensions: 0', { fontSize: '18px', color: '#000' });
    this.worldExtensionText.setScrollFactor(1);

    // Add time left text
    this.timeText = this.add.text(16, 50, 'Time: 60', {
      fontSize: '32px',
      color: '#fff',
      backgroundColor: '#000',
      padding: { x: 10, y: 5 }
    });
    this.timeText.setScrollFactor(1);

    // Start the timer
    this.timeEvent = this.time.addEvent({
      delay: 1000,
      callback: this.updateTimer,
      callbackScope: this,
      loop: true
    });
    this.time.addEvent({
      delay: 10000, // Every 10 seconds
      callback: this.increaseSpawnRate,
      callbackScope: this,
      loop: true
    });

    this.time.addEvent({
      delay: 5000, // Every 5 seconds
      callback: this.increasePlayerSize,
      callbackScope: this,
      loop: true
    });

    this.cursors = this.input.keyboard?.createCursorKeys() as Phaser.Types.Input.Keyboard.CursorKeys;
    this.events.on("resume", () => {
      this.resetGameState();
    });

    this.backgrounds[1].setVisible(false);
    this.backgrounds[2].setVisible(false);

    // create initial platforms
    this.platforms = this.physics.add.staticGroup();
    this.createPlatforms();

    this.player = this.physics.add.sprite(100, 450, "doge");
    this.player.setBounce(0.2);
    this.player.setCollideWorldBounds(true);
    // this.player.body.setAllowGravity(true);
    // this.player.setScale(0.5);
    // Set up camera to follow player
    this.cameras.main.setBounds(0, 0, this.worldWidth, this.scale.height);
    this.cameras.main.startFollow(this.player);

    this.anims.create({
      key: "left",
      frames: this.anims.generateFrameNumbers("doge", { start: 0, end: 3 }),
      frameRate: 10,
      repeat: -1,
    });

    this.anims.create({
      key: "turn",
      frames: [{ key: "doge", frame: 4 }],
      frameRate: 20,
    });

    this.anims.create({
      key: "right",
      frames: this.anims.generateFrameNumbers("doge", { start: 5, end: 8 }),
      frameRate: 10,
      repeat: -1,
    });

    this.physics.add.collider(this.player, this.platforms);

    this.objects = this.physics.add.group();
    this.physics.add.collider(
      this.objects,
      this.platforms,
      this.handleObjectPlatformCollision as unknown as Phaser.Types.Physics.Arcade.ArcadePhysicsCallback,
      undefined,
      this
    );
    this.physics.add.overlap(
      this.player,
      this.objects,
      this.hitObject as unknown as Phaser.Types.Physics.Arcade.ArcadePhysicsCallback,
      undefined,
      this
    );

    this.scoreText = this.add.text(16, 16, "Score: 0", {
      fontSize: "32px",
      color: "#fff",
    });
    this.timerText = this.add
      .text(this.scale.width - 16, 16, "Time: 60", {
        fontSize: "32px",
        color: "#fff",
      })
      .setOrigin(1, 0);

    this.timer = this.time.addEvent({
      delay: 60000,
      callback: this.endGame,
      callbackScope: this,
    });

    this.time.addEvent({
      delay: 1000,
      callback: this.updateTimer,
      callbackScope: this,
      loop: true,
    });

    this.spawnTimer = this.time.addEvent({
      delay: 1000,
      callback: this.spawnObject,
      callbackScope: this,
      loop: true,
    });

    this.time.addEvent({
      delay: 5000,
      callback: this.changeBackgroundDirection,
      callbackScope: this,
      loop: true,
    });

    this.emitter = this.add.particles(this.player.x, this.player.y + 100, "particle", {
      speed: 100,
      scale: { start: 1, end: 0 },
      blendMode: "ADD",
    });

    this.shakeSound = this.sound.add("shake");
    this.jumpSound = this.sound.add("jump");
    this.backgroundMusic = this.sound.add("backgroundMusic", {
      loop: true,
    });
    this.backgroundMusic.play();

    this.setGameStarted(true);

    this.lastPlatformY = this.scale.height - 32;

    // Create power-ups group
    this.powerups = this.physics.add.group();

    // Spawn power-ups periodically
    this.time.addEvent({
      delay: 5000,
      callback: this.spawnPowerup,
      callbackScope: this,
      loop: true
    });
    // If AI toggle is needed, add it here
    if (this.aiControlled !== undefined) {
      this.aiToggle = this.add.text(this.scale.width - 10, 56, 'OFF', {
        fontSize: '18px',
        color: '#ffffff',
        backgroundColor: '#333333',
        padding: { x: 10, y: 5 }
      }).setOrigin(1, 0).setInteractive().setScrollFactor(0);

      this.aiToggle.on('pointerdown', this.toggleAI, this);
    }


    // Collision between player and power-ups
    this.physics.add.overlap(this.player, this.powerups, this.collectPowerup as unknown as Phaser.Types.Physics.Arcade.ArcadePhysicsCallback, undefined, this);

    // Add mode toggle button
    this.modeToggle = this.add.text(this.scale.width - 10, 96, 'CHILL MODE', {
      fontSize: '18px',
      color: '#00ff00',
      backgroundColor: '#333333',
      padding: { x: 10, y: 5 }
    }).setOrigin(1, 0).setInteractive().setScrollFactor(0);

    this.modeToggle.on('pointerdown', this.toggleGameMode, this);
  }
  increaseSpawnRate() {
    if (this.spawnDelay > 200) {
      this.spawnDelay -= 100;
      this.spawnTimer.remove();
      this.spawnTimer = this.time.addEvent({
        delay: this.spawnDelay,
        callback: this.spawnObject,
        callbackScope: this,
        loop: true,
      });
    }

  }
  toggleAI() {
    if (this.aiControlled !== undefined) {
      this.aiControlled = !this.aiControlled;
      this.aiToggle.setText(this.aiControlled ? 'ON' : 'OFF');
      this.aiToggle.setColor(this.aiControlled ? '#00ff00' : '#ffffff');
    }
  }

  increasePlayerSize() {
    if (this.player.scale < 1.5) {
      this.player.setScale(this.player.scale + 0.05);
    }
  }

  updateAiMovement(time: number) {
    // Randomly change direction
    if (Math.random() < 0.02) { // 2% chance to change direction each frame
      this.aiMoveDirection = Phaser.Math.Between(-1, 1);
    }

    if (this.player?.body?.touching.down && time - this.lastJumpTime > 1000) { // 1 second cooldown
      // Random jumps
      if (Math.random() < 0.01) { // 1% chance to jump when on the ground
        this.player.setVelocityY(-330);
        this.lastJumpTime = time;
      }
    }
  
    // Obstacle avoidance
    const nearestObject = this.getNearestObject();
    if (nearestObject instanceof Phaser.Physics.Arcade.Sprite) {
      const distanceX = nearestObject.x - this.player.x;
    if (this.player && this.player.body && this.player.body.touching.down && this.time.now - this.lastJumpTime > this.aiJumpCooldown) {
      // if (Math.random() < 0.01) { // 1% chance to jump when on the ground
      //   this.player.setVelocityY(-330);
      //   this.lastJumpTime = time;
      // }
    }
  }
}

getNearestObject(): Phaser.GameObjects.GameObject | null {
  let nearestObject = null;
  let nearestDistance = Infinity;

  this.objects.getChildren().forEach((object: Phaser.GameObjects.GameObject) => {
    if (object instanceof Phaser.Physics.Arcade.Sprite) {
      const distance = Phaser.Math.Distance.Between(this.player.x, this.player.y, object.x, object.y);
      if (distance < nearestDistance) {
        nearestDistance = distance;
        nearestObject = object;
      }
    }
  });

  return nearestObject;
  }
  getNearestObject2(): Phaser.GameObjects.GameObject | null {
    let nearestObject = null;
    let nearestDistance = Infinity;

    this.objects.getChildren().forEach((object: Phaser.GameObjects.GameObject) => {
      if (object instanceof Phaser.Physics.Arcade.Sprite) {
        const distance = Phaser.Math.Distance.Between(this.player.x, this.player.y, object.x, object.y);
        if (distance < nearestDistance) {
          nearestDistance = distance;
          nearestObject = object;
        }
      }
    });

    return nearestObject;
  }

  update(time: number, delta: number) {
    if (this.gameOver) return;

    if (this.aiControlled) {
      // AI-controlled movement
      this.updateAiMovement(time);
  
      // Apply AI movement
      if (this.aiMoveDirection !== 0) {
        this.player.x += this.aiMoveDirection * 2; // Move 2 pixels per frame
        this.player.anims.play(this.aiMoveDirection === -1 ? 'left' : 'right', true);
      } else {
        this.player.anims.play('turn');
      }
    }

    if (this.score % 50 === 0 && this.score > 0) {
      this.reachMilestone(`${this.score} points!`);
    }
    // Ensure player stays within world bounds
    this.player.x = Phaser.Math.Clamp(this.player.x, 0, this.worldWidth);

    // Scroll background
    this.backgrounds[this.currentBackgroundIndex].tilePositionX = this.cameras.main.scrollX * 0.6;

    // Update texts to stay with camera
    this.worldExtensionText.setPosition(this.cameras.main.scrollX - 100, 16);
    this.timeText.setPosition(this.cameras.main.scrollX - 100, 50);
    // console.log(`AI Move Direction: ${this.aiMoveDirection}, Velocity X: ${this.player?.body?.velocity.x}, Position X: ${this.player?.x}`);
    this.backgrounds[this.currentBackgroundIndex].tilePositionX = this.cameras.main.scrollX * 0.6;

    this.backgrounds[this.currentBackgroundIndex].tilePositionY +=
      this.backgroundDirection;
    this.backgrounds.forEach((bg, index) => {
      bg.tilePositionX = this.cameras.main.scrollX * (index + 1) * 0.1;
    });

    if (this.player.x > this.worldWidth - this.scale.width) {
      this.extendWorld();
    }
    // Update background positions
    this.backgrounds.forEach((bg, index) => {
      bg.tilePositionX = this.cameras.main.scrollX * (index + 1) * 0.1;
    });
    // Add this condition to use keyboard controls on desktop
    if (!this.isTouching) {
      if (this.cursors.left.isDown) {
        this.player.setVelocityX(-160);
        this.player.anims.play("left", true);
      } else if (this.cursors.right.isDown) {
        this.player.setVelocityX(160);
        this.player.anims.play("right", true);
      } else {
        this.player.setVelocityX(0);
        this.player.anims.play("turn");
      }
      if (this.cursors.up.isDown && this.player?.body?.touching.down) {
        this.player.setVelocityY(-330);
      }
        // Allow for double jump
        if (this.cursors.up.isDown && this.cursors.up.getDuration() < 350) {
          if (this.jumps < 2) {
            this.player.setVelocityY(-330);
            this.jumpSound.play();
            this.jumps++;
          }
        }
      }
        // Reset jumps when touching the ground
        if (this.player && this.player?.body?.touching.down) {
          this.jumps = 0;
        }
    
    // Prevent going too far left
    this.player.x = Math.max(this.player.x, this.minPlayerX);

    // Update texts to stay with camera
    this.worldExtensionText.setPosition(this.cameras.main.scrollX + 16, 16);
    this.timeText.setPosition(this.cameras.main.scrollX + 16, 50);
  }

  extendWorld() {
    const oldWorldWidth = this.worldWidth;
    this.worldWidth += this.scale.width;
    this.physics.world.setBounds(0, 0, this.worldWidth, this.scale.height);
    this.cameras.main.setBounds(0, 0, this.worldWidth, this.scale.height);

    // Determine new platform direction
    this.platformDirection = Phaser.Math.RND.pick(['up', 'down', 'straight']);

    // Add new platform segments
    const newSegments = Math.ceil(this.scale.width / this.segmentWidth);
    for (let i = 0; i < newSegments; i++) {
      const x = oldWorldWidth + i * this.segmentWidth + this.segmentWidth / 2;
      let y = this.lastPlatformY;

      switch (this.platformDirection) {
        case 'up':
          y -= Phaser.Math.Between(32, 64);
          break;
        case 'down':
          y += Phaser.Math.Between(32, 64);
          break;
      }

      y = Phaser.Math.Clamp(y, this.scale.height / 2, this.scale.height - 32);

      if (this.platformDirection === 'up' && i % 2 === 0) {
        this.createLadder(x, y);
      } else {
        const platform = this.platforms?.create(x, y, "platform");
        platform.setScale(this.segmentWidth / platform.width, 1).refreshBody();
      }

      this.lastPlatformY = y;
    }

    // Check for milestones
    if (this.worldExtensionCount % 5 === 0) {
      this.reachMilestone(`${this.worldExtensionCount} extensions!`);
      this.timeLeft += 10; // Add 10 seconds
      this.timeText.setText(`Time: ${this.timeLeft}`);
    }

    // Random inspirational messages
    if (Math.random() < 0.2) { // 20% chance each extension
      this.showInspirationMessage();
    }

    // Reward player with extra time every 5 extensions
    if (this.worldExtensionCount % 5 === 0) {
      this.timeLeft += 10; // Add 10 seconds
      this.timeText.setText(`Time: ${this.timeLeft}`);
      console.log('Extra time awarded!');
    }

    // Extend backgrounds
    this.backgrounds.forEach(bg => {
      bg.width = this.worldWidth;
      bg.setTilePosition(this.cameras.main.scrollX, 0);
    });
  }

  createLadder(x: number, y: number) {
    const ladder = this.add.rectangle(x, y - 100, 30, 200, 0xffff00);
    this.physics.add.existing(ladder, true);
    (ladder.body as Phaser.Physics.Arcade.Body).allowGravity = false;
    this.physics.add.overlap(this.player, ladder, () => {
      this.player.setVelocityY(-150); // Move player up when on ladder
    });
  }

  spawnPowerup() {
    const x = this.player.x + Phaser.Math.Between(200, 400);
    const y = Phaser.Math.Between(50, this.scale.height - 50);
    const powerupType = Phaser.Math.RND.pick(['speed', 'jump', 'time']);
    const powerup = this.powerups.create(x, y, `powerup_${powerupType}`);
    powerup.setData('type', powerupType);
  }

  collectPowerup(player: Phaser.Physics.Arcade.Sprite, powerup: Phaser.Physics.Arcade.Sprite) {
    powerup.destroy();
    const type = powerup.getData('type') as 'speed' | 'jump' | 'time';

    switch (type) {
      case 'speed':
        if (this.player.body) {
          this.player.setVelocityX(this.player.body.velocity.x * 1.5);
          this.time.delayedCall(5000, () => {
            if (this.player.body) {
              this.player.setVelocityX(this.player.body.velocity.x / 1.5);
            }
          });
        }
        break;
      case 'jump':
        this.player.setVelocityY(-400);
        break;
      case 'time':
        this.timeLeft += 10;
        this.timeText.setText(`Time: ${this.timeLeft}`);
        break;
    }
    if (type === 'time') {
      this.timeLeft += 10;
      const timeString = `Time: ${this.timeLeft}`;
      this.timeText.setText(timeString);
    }
    this.reachMilestone(`${type?.toUpperCase()} powerup!`);
  }

  reachMilestone(message: string) {
    // Emit particles
    this.emitter.explode(50);

    // Show milestone message
    const milestoneText = this.add.text(this.player.x, this.player.y - 50, message, { fontSize: '24px', color: '#ff0' });
    milestoneText.setOrigin(0.5);
    this.tweens.add({
      targets: milestoneText,
      y: milestoneText.y - 100,
      alpha: 0,
      duration: 2000,
      ease: 'Power2',
      onComplete: () => milestoneText.destroy()
    });
  }

  showInspirationMessage() {
    const messages = [
      "Keep going!",
      "You're doing great!",
      "Awesome progress!",
      "Don't stop now!",
      "You're on fire!"
    ];
    const message = Phaser.Math.RND.pick(messages);
    const inspireText = this.add.text(this.player.x, this.player.y - 30, message, { fontSize: '18px', color: '#0f0' });
    inspireText.setOrigin(0.5);
    this.tweens.add({
      targets: inspireText,
      y: inspireText.y - 50,
      alpha: 0,
      duration: 1500,
      ease: 'Power2',
      onComplete: () => inspireText.destroy()
    });
  }
  changeBackgroundDirection() {
    this.currentBackgroundIndex = (this.currentBackgroundIndex + 1) % this.backgrounds.length;
    this.backgrounds.forEach((bg, index) => {
      bg.setVisible(index === this.currentBackgroundIndex);
    });
  }

  changeBackgroundDirection2() {
    this.backgroundDirection = Phaser.Math.Between(-1, 1);
    this.currentBackgroundIndex =
      (this.currentBackgroundIndex + 1) % this.backgrounds.length;
    this.backgrounds.forEach((bg, index) => {
      bg.setVisible(index === this.currentBackgroundIndex);
    });
  }

  resetGameState() {
    this.score = 0;
    this.coinsCollected = 0;
    this.objectsHit = 0;
    this.jumps = 0;
    this.gameOver = false;
    this.player.setPosition(100, 450);
    this.player.clearTint();
    this.player.setVelocity(0, 0);
    this.updateScoreText();
    this.timer.remove();
    this.timer = this.time.addEvent({
      delay: 60000,
      callback: this.endGame,
      callbackScope: this,
    });
    this.spawnTimer.remove();
    this.spawnTimer = this.time.addEvent({
      delay: 1000,
      callback: this.spawnObject,
      callbackScope: this,
      loop: true,
    });
    this.physics.resume();
    // this.backgroundMusic.play();

    // Reset input
    // this.input.keyboard?.reset();
    if (this.input.keyboard) {
      this.cursors = this.input.keyboard.createCursorKeys();
    } else {
      // If keyboard input is not available, initialize cursors with default values
      this.cursors = {
        up: { isDown: false } as Phaser.Input.Keyboard.Key,
        down: { isDown: false } as Phaser.Input.Keyboard.Key,
        left: { isDown: false } as Phaser.Input.Keyboard.Key,
        right: { isDown: false } as Phaser.Input.Keyboard.Key,
        space: { isDown: false } as Phaser.Input.Keyboard.Key,
        shift: { isDown: false } as Phaser.Input.Keyboard.Key
      };
    }
  }

  spawnObject() {
    if (this.gameOver) return;

    const x = Phaser.Math.Between(this.player.x + 400, this.player.x + 800);

    // const x = Phaser.Math.Between(0, this.scale.width);
    const objectTypes = [
      "asteroid",
      "aliens",
      "ghost",
      "banana",
      "stars",
      "cherry",
      "coin", "powerup_speed", "powerup_jump", "powerup_time"
    ];
    const objectType = Phaser.Utils.Array.GetRandom(objectTypes);
    const object = this.objects.create(x, 0, objectType);
    object.setBounce(0.7); // Add bounce to all objects
    object.setCollideWorldBounds(true);
    object.setVelocity(Phaser.Math.Between(-200, 200), 20);

    const timeLeft = this.timer.getRemainingSeconds();
    const speedMultiplier = 1 + Math.min((60 - timeLeft) / 20, 2);
    object.setVelocityY(object.body.velocity.y * speedMultiplier);
    // Spawn multiple objects based on time
    const objectCount = Math.floor((60 - timeLeft) / 15) + 1;
    for (let i = 1; i < objectCount; i++) {
      this.time.delayedCall(i * 200, () => {
        const newObject = this.objects.create(Phaser.Math.Between(0, this.scale.width), 0, objectType);
        newObject.setBounce(1);
        newObject.setCollideWorldBounds(true);
        newObject.setVelocity(Phaser.Math.Between(-200, 200), 20 * speedMultiplier);
        if (objectType === 'ghost') {
          newObject.setScale(0.3);
        } else {
          newObject.setScale(0.5);
        }
      });
    }

    // Set a smaller scale for ghosts
    if (objectType === 'ghost') {
      object.setScale(0.3);
    } else {
      object.setScale(0.5);
    }
    this.time.delayedCall(7000, () => {
      if (object.active) {
        object.destroy();
      }
    });
  }

  handleObjectPlatformCollision(object: Phaser.Physics.Arcade.Sprite) {
    if (["asteroid", "aliens", "ghost"].includes(object.texture.key)) {
      object.setVelocityY(Phaser.Math.Between(-300, -100));
      this.shakeSound.play();
      this.cameras.main.shake(100, 0.01);
      this.shakePlatform();
      if ("vibrate" in navigator) {
        navigator.vibrate(100);
      }
    } else if (["banana", "cherry", "coin", "stars"].includes(object.texture.key)) {
      // Allow collectibles to bounce
      object.setVelocityY(Phaser.Math.Between(-300, -100) * -0.7);
    } else {
      object.destroy();
    }
  }

  hitObject(
    player: Phaser.Physics.Arcade.Sprite,
    object: Phaser.Physics.Arcade.Sprite
  ) {
    if (
      ["banana", "cherry", "coin", "stars", "powerup_speed", "powerup_jump", "powerup_time"].includes(object.texture.key)
    ) {
      this.incrementScore();
      this.coinsCollected++;
    } else {
      this.decrementScore();
      this.objectsHit++;
      this.shakeSound.play();
      this.cameras.main.shake(100, 0.1);
      this.shakePlatform();
      if ("vibrate" in navigator) {
        navigator.vibrate(1000);
      }
    }
    this.emitter.setPosition(object.x, object.y);
    this.emitter.explode(30);
    object.destroy();
  }

  shakePlatform() {
    this.tweens.add({
      targets: this.platforms.children.entries[0],
      y: this.scale.height - 32 + 5,
      duration: 50,
      yoyo: true,
      repeat: 3,
    });
  }

  incrementScore() {
    this.score += 10;
    this.updateScoreText();
    this.updatePlayerSize();
  }

  decrementScore() {
    this.score = Math.max(0, this.score - 5);
    this.updateScoreText();
    this.updatePlayerSize();
    if (this.score <= 0) {
      this.endGame();
    }
  }

  updateScoreText() {
    this.scoreText.setText(`Score: ${this.score}`);
    this.setScore(this.score);
  }

  updatePlayerSize() {
    const scaleFactor = 1 + Math.min(this.score / 100, 5.5);
    this.player.setScale(scaleFactor);
  }

  // updateTimer() {
  //   if (this.gameOver) return;
  //   const remainingTime = Math.ceil(this.timer.getRemainingSeconds());
  //   this.timerText.setText(`Time: ${remainingTime}`);
  //   this.setTime(remainingTime);
  //   if (remainingTime <= 0) {
  //     this.endGame();
  //   }
  // }

  updateTimer() {
    if (this.gameOver) return;
    this.timeLeft--;
    this.timeText.setText(`Time: ${this.timeLeft}`);
    const timeString = `Time: ${this.timeLeft}`;
    this.timeText.setText(timeString);

    if (this.timeLeft <= 0) {
      this.endGame();
    }
  }
  submitScore = async (score: number, coinsCollected: number, objectsHit: number) => {
    try {
      const { data, error } = await supabase
        .from('scores')
        .insert([
          { score: score, player_name: this.playerName,
            created_at: new Date().toISOString(),
            wallet_address: '0x123',
            coins_collected: coinsCollected,
            objects_hit: objectsHit,
           } // add player name input later
        ]);

      if (error) throw error;
      console.log('Score submitted successfully');
    } catch (error) {
      console.error('Error submitting score:', error);
    }
  }

  endGame() {
    if (this.gameOver) return;
    this.submitScore(this.score, this.coinsCollected, this.objectsHit);
    this.physics.pause();
    this.player.setTint(0xff0000);
    this.gameOver = true;
    this.timeEvent.remove();
    this.spawnTimer.remove();
    this.backgroundMusic.stop();
    this.setGameOver(true);
    this.setScore(this.score);
    this.setTime(Math.ceil(this.timer.getElapsedSeconds()));
    this.setGameOverTimestamp(new Date().toUTCString());
    // Add these lines
    this.setCoinsCollected(this.coinsCollected);
    this.setObjectsHit(this.objectsHit);
  }

  resetGame() {
    this.scene.restart();
  }

  toggleGameMode() {
    this.isInsaneMode = !this.isInsaneMode;
    if (this.isInsaneMode) {
      this.modeToggle.setText('MEME MADNESS');
      this.modeToggle.setColor('#ff00ff');
      this.activateInsaneMode();
    } else {
      this.modeToggle.setText('CHILL MODE');
      this.modeToggle.setColor('#00ff00');
      this.deactivateInsaneMode();
    }
  }

  activateInsaneMode() {
    // Increase game speed
    this.physics.world.timeScale = 1.5;
    
    // Add screen shake
    this.cameras.main.shake(500, 0.005, true);
    
    // Increase spawn rate
    this.spawnDelay = Math.max(200, this.spawnDelay - 300);
    this.spawnTimer.remove();
    this.spawnTimer = this.time.addEvent({
      delay: this.spawnDelay,
      callback: this.spawnObject,
      callbackScope: this,
      loop: true,
    });

    // Add vibration if available
    if ("vibrate" in navigator) {
      navigator.vibrate([100, 50, 100]);
    }
  }

  deactivateInsaneMode() {
    // Reset game speed
    this.physics.world.timeScale = 1;
    
    // Stop screen shake
    this.cameras.main.shake(0, 0);
    
    // Reset spawn rate
    this.spawnDelay = 1000;
    this.spawnTimer.remove();
    this.spawnTimer = this.time.addEvent({
      delay: this.spawnDelay,
      callback: this.spawnObject,
      callbackScope: this,
      loop: true,
    });
  }
}


const MemeMayhemGame: React.FC = () => {
  const gameRef = useRef<HTMLDivElement>(null);
  const gameInstanceRef = useRef<Phaser.Game | null>(null);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [time, setTime] = useState(60);
  const [gameStarted, setGameStarted] = useState(false);
  const [showAiToggle, setShowAiToggle] = useState(false);
  const [gameOverTimestamp, setGameOverTimestamp] = useState<string | null>(null);
  const [shake, setShake] = useState(false);
  const [coinsCollected, setCoinsCollected] = useState(0);
  const [objectsHit, setObjectsHit] = useState(0);

  useEffect(() => {
    if (gameOver) {
      setShake(true);
      const timer = setTimeout(() => {
        setShake(false);
      }, 10000); // Stop shaking after 10 seconds
      return () => clearTimeout(timer);
    }
  }, [gameOver]);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setShowAiToggle(urlParams.has('ai'));

    const gameScene = new GameScene(
      setScore, setGameOver, setGameStarted, setTime, 
      setGameOverTimestamp, setCoinsCollected, setObjectsHit,
    );
    const config: Phaser.Types.Core.GameConfig = {
      type: Phaser.AUTO,
      width: window.innerWidth,
      height: window.innerHeight,
      parent: gameRef.current,
      scene: [Boot, Preloader, MainMenu, NameInputScene, InstructionsScene, gameScene],
      scale: {
        mode: Phaser.Scale.RESIZE,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        parent: "phaser-container",
        width: "100%",
        height: "100%",
      },
      physics: {
        default: "arcade",
        arcade: {
          gravity: { y: 300, x: 0 },
          debug: false,
        },
      },
      backgroundColor: "#000000", // Set a background color to avoid white edges
      plugins: {
        scene: [{
          key: 'rexUI',
          plugin: UIPlugin,
          mapping: 'rexUI'
        }]
      }    
    };
    
    gameInstanceRef.current = new Phaser.Game(config);
    
    return () => {
      if (gameInstanceRef.current) {
        gameInstanceRef.current.destroy(true);
      }
    };

  }, []);

  const handleStartRestart = () => {
    if (gameOver) {
      window.location.reload();
      // if (gameInstanceRef.current) {
      //   const scene = gameInstanceRef.current.scene.scenes[0] as Phaser.Scene;
      //   scene.scene.restart();
      //   setGameOver(false);
      //   setScore(0);
      //   setTime(60);
      // }
    } else {
      setGameStarted(true);
    }
  };

  const takeScreenshot = async () => {
    const gameContainer = document.getElementById('phaser-container') as HTMLElement;
    const overlayElement = document.querySelector('#game-over-overlay') as HTMLElement;
    
    if (gameContainer) {
      // Create a temporary container
      const tempContainer = document.createElement('div');
      tempContainer.style.position = 'absolute';
      tempContainer.style.left = '-9999px';
      tempContainer.style.top = '-9999px';
      tempContainer.style.width = `${gameContainer.clientWidth}px`;
      tempContainer.style.height = `${gameContainer.clientHeight}px`;
      tempContainer.style.backgroundImage = 'url(https://labs.phaser.io/assets/skies/space3.png)';
      tempContainer.style.backgroundSize = 'cover';
      tempContainer.style.backgroundPosition = 'center';
  
      document.body.appendChild(tempContainer);
      // Clone the game container
      const gameClone = gameContainer.cloneNode(true) as HTMLElement;
      gameClone.style.width = `${gameContainer.clientWidth}px`;
      gameClone.style.height = `${gameContainer.clientHeight}px`;
  
      // Add a colorful background
      // tempContainer.appendChild(gameClone);
      if (overlayElement) {
        const overlayClone = overlayElement.cloneNode(true) as HTMLElement;
        overlayClone.style.position = 'absolute';
        overlayClone.style.top = '50%';
        overlayClone.style.left = '50%';
        overlayClone.style.transform = 'translate(-50%, -50%)';
        tempContainer.appendChild(overlayClone);
      }
        // Take the screenshot
      const canvas = await html2canvas(tempContainer, {
        useCORS: true,
        logging: false,
          backgroundColor: null,
      });
      const dataUrl = canvas.toDataURL('image/png');
  
      // Clean up
      document.body.removeChild(tempContainer);
  
      // Download the image
      const link = document.createElement('a');
      link.download = `meme-mayhem-screenshot-${new Date().toISOString()}.png`;
      link.href = dataUrl;
      link.click();
    }
  };
  const toggleAiControl = () => {
    if (gameInstanceRef.current) {
      const scene = gameInstanceRef.current.scene.getScene('GameScene') as GameScene;
      scene.aiControlled = true; //!scene.aiControlled;
    }
  };

  return (
    <motion.div
      className={shake ? "shake" : ""}
      id="phaser-container"
      ref={gameRef}
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {gameOver && (
        <motion.div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "#fff",
          textAlign: "center",
          backgroundImage: "url(https://labs.phaser.io/assets/skies/space3.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "20px",
          borderRadius: "10px",
          zIndex: 10,
          width: "80%", // Adjust as needed
          maxWidth: "500px", // Adjust as needed
          boxShadow: "0 0 20px rgba(0,0,0,0.5)",
        }}
        id="game-over-overlay"
        animate={shake ? { x: [-5, 5, -5, 5, 0] } : {}}
        transition={shake ? { repeat: Infinity, duration: 0.5 } : {}}
      >
        <h1 style={{ fontSize: "2.5em", marginBottom: "20px" }}>Game Over</h1>
        <p style={{ fontSize: "1.2em" }}>Your Score: {score} </p>
        <p style={{ fontSize: "1.2em" }}>Coins Collected: {coinsCollected}</p>
        <p style={{ fontSize: "1.2em" }}>Objects Hit: {objectsHit}</p>
        <p style={{ fontSize: "1em", opacity: 0.8 }}>Timestamp: {gameOverTimestamp}</p>

          <motion.button onClick={handleStartRestart}
            className={`px-4 py-2 rounded-md flex items-center bg-white text-indigo-600`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
          >
            Restart Game
          </motion.button>
          <motion.button onClick={takeScreenshot}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
            style={{ marginLeft: '10px' }}
          >
            Take Screenshot
          </motion.button>
        </motion.div>
      )}
    </motion.div>
  );
};

export default MemeMayhemGame;
