import Phaser from 'phaser';
import RexUIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';

// Add this type declaration
declare module 'phaser' {
    interface Scene {
      rexUI: RexUIPlugin;
    }
  }
  
export default class NameInputScene extends Phaser.Scene {
  private nameText!: Phaser.GameObjects.Text;
  private submitButton!: Phaser.GameObjects.Text;
  private skipButton!: Phaser.GameObjects.Text;
  private currentName: string = '';
  private instructionsButton!: Phaser.GameObjects.Text;

  constructor() {
    super('NameInputScene');
  }

  create() {
    const { width, height } = this.scale;

    // Add a background
    this.add.rectangle(0, 0, width, height, 0x000000, 0.7).setOrigin(0);

    // Add a title
    const title = this.add.text(width / 2, height / 4, 'Enter Your Meme Name', {
      fontSize: '32px',
      color: '#00ff00'
    }).setOrigin(0.5);

    // Create the name input text
    this.nameText = this.add.text(width / 2, height / 2, 'Click to enter name', {
        fontSize: '24px',
        color: '#00ff00',
        align: 'center',
        fixedWidth: 300,
        backgroundColor: '#333333',
        padding: { x: 10, y: 5 }
      }).setOrigin(0.5).setInteractive();
    // Add click handler for name input
    this.nameText.on('pointerdown', () => {
        this.handleNameInput();
        this.nameText.setText('|'); // Show a cursor
    });

    // Create submit button
    this.submitButton = this.add.text(width / 2, height * 3 / 4, 'Submit', {
      fontSize: '24px',
      color: '#00ff00'
    }).setOrigin(0.5).setInteractive();

    // Create skip button
    this.skipButton = this.add.text(width / 2, height * 3 / 4 + 50, 'Skip', {
      fontSize: '18px',
      color: '#888888'
    }).setOrigin(0.5).setInteractive();

    // Add hover effects
    this.submitButton.on('pointerover', () => this.submitButton.setScale(1.1));
    this.submitButton.on('pointerout', () => this.submitButton.setScale(1));
    this.skipButton.on('pointerover', () => this.skipButton.setScale(1.1));
    this.skipButton.on('pointerout', () => this.skipButton.setScale(1));

    // Add click handlers
    this.submitButton.on('pointerdown', this.handleSubmit, this);
    this.skipButton.on('pointerdown', this.handleSkip, this);

    // Add instructions button
    this.instructionsButton = this.add.text(width / 2, height * 3 / 4 + 100, 'How to Play', {
      fontSize: '24px',
      color: '#00ff00'
    }).setOrigin(0.5).setInteractive();

    this.instructionsButton.on('pointerdown', this.showInstructions, this);

    // Add some fun animations
    this.tweens.add({
      targets: title,
      y: title.y - 10,
      duration: 1000,
      yoyo: true,
      repeat: -1
    });

    this.tweens.add({
      targets: this.submitButton,
      alpha: 0.5,
      duration: 500,
      yoyo: true,
      repeat: -1
    });
  }

  handleNameInput() {
    this.input?.keyboard?.on('keydown', (event: KeyboardEvent) => {
        if (event.keyCode === 8 && this.currentName.length > 0) {
          // Handle backspace
          this.currentName = this.currentName.slice(0, -1);
        } else if (event.keyCode === 32 || (event.keyCode >= 48 && event.keyCode <= 90)) {
          // Handle space and alphanumeric characters
          this.currentName += event.key;
        }
  
        // Update the displayed text
        this.nameText.setText(this.currentName || 'Click to enter name');
      });
    }

  handleSubmit() {
    const name = this.nameText.text.trim() || 'Anonymous Memer';
    this.saveName(name);
    this.startGame();
  }

  handleSkip() {
    this.saveName('Anonymous Memer');
    this.startGame();
  }

  saveName(name: string) {
    localStorage.setItem('playerName', name);
  }

  startGame() {
    // Transition to the main game scene
    this.scene.start('GameScene');
  }

  showInstructions() {
    this.scene.launch('InstructionsScene');
  }
}