import * as Phaser from "phaser";
export class MainMenu extends Phaser.Scene {
  constructor() {
    super("MainMenu");
  }

  create() {
    const score = this.registry.get("highscore");
    const width = this.scale.width;
    const height = this.scale.height;

    const textStyle = {
      fontFamily: "Arial Black",
      fontSize: 38,
      color: "#ffffff",
      stroke: "#000000",
      strokeThickness: 8,
    };
    // Add background image and scale it to cover the screen
    const background = this.add.image(width / 2, height / 2, "background");
    const scaleX = width / background.width;
    const scaleY = height / background.height;
    const scale = Math.max(scaleX, scaleY);
    background.setScale(scale).setScrollFactor(0);
    const logo = this.add.image(width / 2, -height * 0.2, "logo");
    logo.setScale(Math.min(width, height) * 0.001); // Adjust scale as needed

    this.tweens.add({
      targets: logo,
      y: height * 0.2,
      duration: 1000,
      ease: "Bounce",
    });
    this.add.text(
      width * 0.05,
      height * 0.05,
      `High Score: ${score}`,
      textStyle
    );

    const instructions = [
      "jump into our Meme Dodge Game,",
      "rack up points, and win big!",
      "",
      "Click to Start!",
    ];

    // Add instructions text at the bottom center
    this.add
      .text(width / 2, height * 0.8, instructions, textStyle)
      .setAlign("center")
      .setOrigin(0.5);

    this.input.once("pointerdown", () => {
      this.scene.start("NameInputScene");
    });
  }
}
