import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './GameStory.css';

const GameStory: React.FC = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <motion.div 
      className="game-story"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2>The Legend of Fling & Blip: Meme Mayhem Adventure</h2>
      <motion.p 
        initial={{ height: 0 }}
        animate={{ height: expanded ? 'auto' : '100px' }}
        transition={{ duration: 0.5 }}
        className="story-content"
      >
        Meet Fling, the coolest kid with rainbow hair and star shades, and his secret sidekick Blip, a shape-shifting meme monster! 🌈😎

        Together, they're diving into the wild world of Meme Dodge to become legendary gamers and strike it rich! 🎮💰

        In this crazy game:
        • Dodge flying memes and grab coins faster than you can say "HODL"! 💨
        • Discover hidden power-ups that'll make you go "Wow!" 🤯
        • Climb the leaderboard and show off your meme-dodging skills! 🏆
        • Earn real tokens and become a meme millionaire! 🤑

        Are you ready to join Fling and Blip on their mission to meme greatness and fortune? Jump in, dodge those memes, and let's get rich! 🚀
      </motion.p>
      <motion.button 
        onClick={() => setExpanded(!expanded)}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {expanded ? 'Show Less' : 'Read More'}
      </motion.button>
    </motion.div>
  );
};

export default GameStory;
